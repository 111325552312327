<div class="background-image"></div>
<div>

    <div class="flex-container">
        <div class="title"><h1>{{flowData['question']}}</h1></div>
        <div class="answers">
            <div class="answer" *ngFor="let answer of flowData['answers']" (click)="nextStep(answer)">
                {{answer.title}}
            </div>
        </div>
        
    </div>

</div>
