import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { trigger, transition, animate, style, state } from '@angular/animations';



@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  // animations: [
  //   trigger('textAnim', [
  //     transition('void => *', [
  //       style({
  //         transform: 'scale(2,2)',
  //         filter: 'blur(10px)'
  //       }),
  //       animate('0.2s ease-in')
  //     ]),
  //     transition('* => void', [
  //       style({
  //         transform: 'scale(2,2)',
  //         filter: 'blur(10px)'
  //       }),
  //       animate('0.2s ease-out')
  //     ])
  //   ])
  // ]
})


export class HomeComponent implements OnInit {
  text: Object;
  flowData: Object = {};
  allFlowData: Object;
  nextNode: Object;
  end: boolean = false;
  commandmentsCount: any = 4;
  trespasses: Array<string> = [];
  trespassesOutput: Array<string> = [];
  deathScreen: boolean = false;
  id: string;
  dbData: Object = {
    flow: []
  };
  localDate: Date = new Date;
  rating: any = 0;
  email: any;
  feedback: any;
  thanks: boolean = false;
  deathCauses: any;
  year: Number = new Date().getFullYear();
  links = new Map();

  constructor(private http: HttpClient) { }

  ngOnInit(): void {


    this.getFlowChart();

  }


  
  
  getFlowChart() {
    this.http.get('assets/flow.mup', {responseType: 'json'}).subscribe(
      data => {

        // Get Questions and Answers Flow
        //todo: make it dynamic
        let currentFlow = data['ideas'][2]['ideas'][1];
        this.allFlowData = data['ideas'];
        
        this.flowData = this.populateFlowData(currentFlow);

        // Get Links
        for (let link of data['links']) {
          this.links.set(link['ideaIdFrom'], link['ideaIdTo']);
        }        
        
      }
    );
  }

  checkNote(data) {
    let output = false;
    if (data.attr?.note?.text != undefined) {
      // Add Code what to do
    }

    return output;
  }

  populateFlowData(data) {


    let question = data['title'];
    let answers = data['ideas'];
    
    // Check if we arrived at the commandments text output
    // if(this.checkNote(data)) {

    // };

    //add to DB Data
    this.dbData['flow'].push(question);

    // Check if it contains answers

    if (answers == undefined || Object.keys(answers).length == 0) {
      answers = null;
      this.goToEnd();
    } else {
      answers = Object.values(answers)
    }
    console.log(question);
    return {
      question: question,
      answers:  answers
    };    
  }

  //Go to Next Step/Question
  nextStep(data) {


    // Check if answer has note/trespass
    this.checkNote(data);

    // Check if node has children
    if (data.hasOwnProperty('ideas') && Object.keys(data['ideas']).length !== 0) {
      
      //Get First Object under ideas
      var nextData = {}
      for (let value in data.ideas) {
        nextData = data.ideas[value];
        break;
      }
      


      this.flowData = this.populateFlowData(nextData);


    } else {
      
      //Check if end of flow is linked to another node and continue there
      if (this.links.has(data.id)) {
    
        let linkedNode = this.links.get(data.id);
        let nextNode = this.findNode(this.allFlowData, linkedNode);

        // Check result. If found, display next node, else end of nodes.
        if (nextNode) {
          this.flowData = this.populateFlowData(nextNode);
        } else {
          this.goToEnd();
        }
                
      } else {      
        this.goToEnd();
      }

    }

    //Scroll back to top
    // setTimeout(function() {
    //   document.body.scrollTop = 0;
    //   document.documentElement.scrollTop = 0;
    // }, 100)
  }



  // If at End of Flow
  goToEnd() {
    this.end = true;
  }

  // Find Node with specific Id
  findNode(data, id) {

    for (let value in data) {
      
      if (data[value].id == id) {
        return data[value];
      }

      if (typeof(data[value]) == 'object') {
        let result = this.findNode(data[value], id);

        if (result != null) {
          return result;
        }
      }

    } 

    return null;
  }

}
